import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FWBanner from '../../components/fullWidthBanner';




const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
    promoHeader: theme.typography.promoHeader,
    paragraph: {
        marginBottom: theme.spacing(5),
        fontSize:'1.2em'
    },
    mt5: {
        marginTop: '50px',
        
    }

}))

export default function WhoWeAreTr(props) {
    const classes = useStyles()
    const locale = 'tr-TR'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Biz kimiz?' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='about-us-desktop.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <WhoWeAreHeader />
                    <WhoWeAreBodyTr />


                </Grid>
            {/*     <Grid item className={classes.mt5} xs={12}>
                    <MenuBoxContainer menuItems={menuBoxProps} />
                </Grid> */}

            </Grid>
        </Layout>
    )

}

const WhoWeAreHeader = props => {
    const classes = useStyles()
    return (
        <Box mt={5}>
            <Typography component='h1' className={classes.promoHeader}>
                Biz kimiz?
            </Typography>
        </Box>
    )

}

const WhoWeAreBodyTr = props => {
    const classes = useStyles()
    return (
        <Box >
            <Typography  component='p' display='block' className={classes.paragraph}>
                Prochoice, hayvan sevgisi ile bir araya gelmiş üç arkadaşın, &#39;Kendimizin
                yemeyeceği hiçbir şeyi hayvanlarımıza yedirmeyeceğiz&#39; misyonu ile kuruldu.
                Bugün 150 kişi, 26 köpek ve 30 kedili kocaman bir aileyiz.
            </Typography>
            <Typography component='p' className={classes.paragraph}>
                Tutkumuz, tabii ki dört ayaklı dostlarımız.Onların mutlu ve sağlıklı bir yaşam
                sürmeleri, en önemli önceliğimiz.Kendimize her anlamda çok yakın hissettiğimiz
                dostlarımızın, yemek düşkünlüğü konusunda da bizden farklı olmadıklarına
                inanıyoruz.Doğal ve taze içerikler kullanarak hazırladığımız, iştah açıcı
                mamalarımızdan yerken, kedi ve köpekleriniz kendilerini birer gurme gibi
                hissedecek!
            </Typography>
            <Typography component='p' display='block' className={classes.paragraph}>
                Mama seçimi konusunda, siz değerli evcil hayvan ebeveynlerinin ne kadar
                hassas olduğunu çok iyi biliyoruz.Mamaların hazırlanma sürecinde, kalite ve
                hijyen standartlarımızı en yüksekte tutuyoruz.Dostlarımızın ihtiyaçlarını daha iyi
                anlayabilmek için, dünyanın dört bir yanından veteriner hekimler ve beslenme
                uzmanları ile birlikte çalışarak tariflerimizi hazırlıyor; en iyi kalitede malzemeler
                seçiyor ve en hızlı şekilde mamalarımızı servise hazır hale getiriyoruz.
            </Typography>

        </Box>
    )
}



