import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import Img from 'gatsby-image'


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',

    },
    image: {
        width: '100%',
        maxHeight: '700px',
        
    },
    textWrapper: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        '&$left': {
            left: '5%',
            top: '50%',
            transform: 'translateY(-50%);'

        },
        '&$right': {
            right: '5%',
            top: '50%',
            transform: 'translateY(-50%);'
        },
        [theme.breakpoints.down('md')]:{
            width:'35vW',

        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

    },
    left: {},
    right: {},
    dark: {
        color: 'white'
    },
    header: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 600,
        fontSize: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem'
        }
    },
    subtitle: {
        fontSize: '1.3rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    controls: {
        display: 'flex',
        width: '50%',
        gap: '2%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    button: {
        whiteSpace: 'nowrap',
        color: 'white',
        backgroundColor: 'black',
        borderRadius: 0,
        "&:hover, &:focus": {
            color: 'black',
            backgroundColor: 'white',
        },
        '&$dark': {
            color: 'black',
            backgroundColor: 'white',
            "&:hover, &:focus": {
                color: 'white',
                backgroundColor: 'black',
            },
        }
    },

}))

const BannerActions = ({ actions, dark }) => {
    const classes = useStyles()
    return (
        <div className={classes.controls}>
            {actions.map((action, index) => {
                return <Button fullWidth component={Link} to={action.to} key={index} className={`${classes.button} ${dark? classes.dark:''}`}>{action.label}</Button>
            })}
        </div>
    )
}

const FWBanner = props => {
    const data = useStaticQuery(
        graphql`
      query {
        photo: allFile(filter: {sourceInstanceName: {eq:"images"}}) {
        nodes {
            relativePath
            childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
            }
          }

        }
        
        }
      }
    `)
    const displayText = props.hasOwnProperty('title') & props.hasOwnProperty('subtitle')
    const { actions } = props
    const classes = useStyles()
     /* eslint eqeqeq: 0 */
    const heroImg = data.photo.nodes.find(el => el.relativePath == props.imgName)
    return (
        <Box className={classes.root}>
            <Img fluid={heroImg.childImageSharp.fluid} className={classes.image} />
            {displayText===1 &&
                <div className={`${classes.textWrapper} ${props.hasOwnProperty('left') ? classes.left : classes.right} ${props.hasOwnProperty('dark') ? classes.dark : ''}`}>
                    <Typography className={classes.header} noWrap>{props.title}</Typography>
                    <Typography className={classes.subtitle} gutterBottom>{props.subtitle}</Typography>
                    {actions?.length > 0 && <BannerActions actions={actions} dark={props.hasOwnProperty('dark')}/>}
                </div>
            }

        </Box>
    )
}


export default FWBanner